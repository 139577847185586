import React, { useState } from 'react';
import styles from './App.module.css'; // Импорт стилей
import Logo from '../components/Assets/logo.webp';
import Hero from '../components/Assets/hero.webp';
import Casino from '../components/Assets/casino.webp';
import About2 from '../components/Assets/About2.webp';
import TigerImage from '../components/Assets/Tiger1.webp';
import TigerImage2 from '../components/Assets/Tigerimage2.webp';
import TigerImage3 from '../components/Assets/TigerImage3.webp';

const slides = [
  {
    image: TigerImage,
    text: 'Descrição do primeiro slide.',
  },
  {
    image: TigerImage2,
    text: 'Descrição do segundo slide.',
  },
  {
    image: TigerImage3,
    text: 'Descrição do terceiro slide.',
  },
];

const App = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <img src={Logo} alt="Tiger Fortune" className={styles.logo} />
        <button className={styles.playButton}>Jogar</button>
      </header>
      <main>
      <section className={styles.hero}>
      <div className={styles.textContainer}>
        <h1 className={styles.heroTitle}>Jogo "Tigre" "Fortuna"</h1>
        <p className={styles.heroSubtitle}>Caçando a sorte na selva da emoção</p>
      </div>
      <img src={Hero} alt="Tiger" className={styles.heroImage} />
    </section>
    <section className={styles.about}>
  <h2>Sobre o jogo</h2>
  <p>
    Tigre Fortuna é um vídeo caça-níqueis emocionante. O jogo leva você ao coração de uma selva exuberante, onde o
    indescritível tigre da fortuna se esconde entre vinhas e ruínas antigas. O slot oferece uma jogabilidade
    emocionante com gráficos coloridos, som atmosférico e uma chance de grandes ganhos.
  </p>
</section>
<section className={styles.characteristics}>
  <div className={styles.textSection}>
    <h2>Características do jogo</h2>
    <div className={styles.characteristicsGrid}>
      <div className={styles.characteristic}>
        <p>Volatilidade da sessão</p>
        <p className={styles.highlight}>Média</p>
      </div>
      <div className={styles.characteristic}>
        <p>Vitória máxima</p>
        <p className={styles.highlight}>x2500</p>
      </div>
      <div className={styles.characteristic}>
        <p>RTP (porcentagem de retorno teórico)</p>
        <p className={styles.highlight}>96,81%</p>
      </div>
      <div className={styles.characteristic}>
        <p>Tamanho real</p>
        <p className={styles.highlight}>18,5MB</p>
      </div>
      <div className={styles.characteristic}>
        <p>Primeira inicialização</p>
        <p className={styles.highlight}>18,5MB</p>
      </div>
    </div>
  </div>
  <div className={styles.imageSection}>
    <img src={Casino} alt="Game Screenshot" />
  </div>
</section>
<section className={styles.about2}>
  <h2>História do jogo</h2>
  <p>
  Tigre Fortuna é o resultado do trabalho de uma equipe de desenvolvimento profissional que se esforçou para criar uma experiência de jogo divertida e segura para os usuários. O jogo passa regularmente pelos testes necessários de imparcialidade e conformidade com todos os padrões da indústria de jogos.
  </p>
</section>
<section className={styles.rulesSection}>
      <div className={styles.textSection}>
        <h2>Regras do jogo</h2>
        <p>
          O jogo tem 5 rolos e 20 linhas de pagamento. O objetivo é coletar combinações de símbolos idênticos nas linhas ativas. Quanto mais símbolos valiosos você coletar, maiores serão seus ganhos. O jogo também contém símbolos especiais - Wild e Scatter, que ativam bônus adicionais e rodadas grátis.
        </p>
      </div>
      <ul className={styles.rulesList}>
        <li>Aposta mínima: R$ 0,4</li>
        <li>Aposta máxima: 60 reais</li>
        <li>Multiplicador máximo: 200x</li>
        <li>RTP (Retorno ao Jogador): 97%</li>
      </ul>
    </section>
    <section className={styles.howToPlaySection}>
  <div className={styles.container}>
    <div className={styles.contentWrapper}>
      <div className={styles.textContent}>
        <h2>Como jogar</h2>
        <p>
          Tigre Fortuna é uma slot machine licenciada e com alta classificação entre os jogadores, 
          que atrai pelo seu design colorido, jogabilidade viciante e jogabilidade segura. 
          Veja como começar a jogar:
        </p>
        <div className={styles.indicators}>
          {slides.map((_, index) => (
            <span
              key={index}
              className={`${styles.indicator} ${index === currentSlide ? styles.activeIndicator : ''}`}
              onClick={() => goToSlide(index)}
            />
          ))}
        </div>
      </div>
      <div className={styles.sliderContainer}>
        <button className={styles.arrow} onClick={prevSlide}>‹</button>
        <div className={styles.slider}>
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`${styles.slide} ${index === currentSlide ? styles.active : ''}`}
            >
              {index === currentSlide && (
                <img src={slide.image} alt={`Slide ${index}`} className={styles.tigerImage} />
              )}
            </div>
          ))}
        </div>
        <button className={styles.arrow} onClick={nextSlide}>›</button>
      </div>
    </div>
  </div>
</section>

<footer className={styles.footer}>
        <p>
          As máquinas caça-níqueis destinam-se exclusivamente ao entretenimento de adultos. Jogue com responsabilidade, lembre-se que este é um jogo, não uma forma de ganhar dinheiro. O uso excessivo de caça-níqueis pode levar ao vício do jogo. Se você sente que o jogo está começando a controlar sua vida, procure ajuda profissional. Lembre-se que o jogo deve ser divertido e não se tornar um problema. Jogue caça-níqueis apenas se tiver 18 anos de idade ou mais.
        </p>
      </footer>
       
      </main>
    </div>
 );
};
export default App;